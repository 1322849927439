// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-bottlebooks-gatsby-theme-default-src-pages-404-js": () => import("./../../../node_modules/@bottlebooks/gatsby-theme-default/src/pages/404.js" /* webpackChunkName: "component---node-modules-bottlebooks-gatsby-theme-default-src-pages-404-js" */),
  "component---node-modules-bottlebooks-gatsby-theme-event-src-pages-favorites-js": () => import("./../../../node_modules/@bottlebooks/gatsby-theme-event/src/pages/favorites.js" /* webpackChunkName: "component---node-modules-bottlebooks-gatsby-theme-event-src-pages-favorites-js" */),
  "component---node-modules-bottlebooks-gatsby-theme-event-src-pages-preview-js": () => import("./../../../node_modules/@bottlebooks/gatsby-theme-event/src/pages/preview.js" /* webpackChunkName: "component---node-modules-bottlebooks-gatsby-theme-event-src-pages-preview-js" */),
  "component---node-modules-bottlebooks-gatsby-theme-event-src-pages-tasting-list-js": () => import("./../../../node_modules/@bottlebooks/gatsby-theme-event/src/pages/tastingList.js" /* webpackChunkName: "component---node-modules-bottlebooks-gatsby-theme-event-src-pages-tasting-list-js" */),
  "component---node-modules-bottlebooks-gatsby-theme-event-src-templates-producers-template-js": () => import("./../../../node_modules/@bottlebooks/gatsby-theme-event/src/templates/ProducersTemplate.js" /* webpackChunkName: "component---node-modules-bottlebooks-gatsby-theme-event-src-templates-producers-template-js" */),
  "component---src-bottlebooks-gatsby-theme-event-templates-exhibitor-template-js": () => import("./../../../src/@bottlebooks/gatsby-theme-event/templates/ExhibitorTemplate.js" /* webpackChunkName: "component---src-bottlebooks-gatsby-theme-event-templates-exhibitor-template-js" */),
  "component---src-bottlebooks-gatsby-theme-event-templates-exhibitors-template-js": () => import("./../../../src/@bottlebooks/gatsby-theme-event/templates/ExhibitorsTemplate.js" /* webpackChunkName: "component---src-bottlebooks-gatsby-theme-event-templates-exhibitors-template-js" */),
  "component---src-bottlebooks-gatsby-theme-event-templates-producer-template-js": () => import("./../../../src/@bottlebooks/gatsby-theme-event/templates/ProducerTemplate.js" /* webpackChunkName: "component---src-bottlebooks-gatsby-theme-event-templates-producer-template-js" */),
  "component---src-bottlebooks-gatsby-theme-event-templates-product-template-js": () => import("./../../../src/@bottlebooks/gatsby-theme-event/templates/ProductTemplate.js" /* webpackChunkName: "component---src-bottlebooks-gatsby-theme-event-templates-product-template-js" */),
  "component---src-bottlebooks-gatsby-theme-event-templates-products-template-js": () => import("./../../../src/@bottlebooks/gatsby-theme-event/templates/ProductsTemplate.js" /* webpackChunkName: "component---src-bottlebooks-gatsby-theme-event-templates-products-template-js" */),
  "component---src-pages-explore-by-importer-js": () => import("./../../../src/pages/explore/by-importer.js" /* webpackChunkName: "component---src-pages-explore-by-importer-js" */),
  "component---src-pages-explore-by-variety-js": () => import("./../../../src/pages/explore/by-variety.js" /* webpackChunkName: "component---src-pages-explore-by-variety-js" */),
  "component---src-pages-explore-index-js": () => import("./../../../src/pages/explore/index.js" /* webpackChunkName: "component---src-pages-explore-index-js" */),
  "component---src-pages-explore-new-releases-js": () => import("./../../../src/pages/explore/new-releases.js" /* webpackChunkName: "component---src-pages-explore-new-releases-js" */),
  "component---src-pages-explore-seeking-representation-js": () => import("./../../../src/pages/explore/seeking-representation.js" /* webpackChunkName: "component---src-pages-explore-seeking-representation-js" */),
  "component---src-pages-q-exhibitor-id-js": () => import("./../../../src/pages/q/[exhibitorId].js" /* webpackChunkName: "component---src-pages-q-exhibitor-id-js" */),
  "component---src-pages-q-exhibitor-id-product-id-js": () => import("./../../../src/pages/q/[exhibitorId]/[productId].js" /* webpackChunkName: "component---src-pages-q-exhibitor-id-product-id-js" */)
}

