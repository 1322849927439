module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@bottlebooks/gatsby-theme-event/gatsby-browser.js'),
      options: {"plugins":[],"rootPath":"/","homePath":"/producers","exhibitorBase":"partners","producerBase":"producers","productBase":"products","withOverlay":false,"showCountryFilter":true,"landingPageSort":{"fields":["contentLevel","sortName"],"order":["DESC","ASC"]},"exhibitorSort":{"fields":["event___sortOrder","stand___sortOrder","contentLevel","sortName"],"order":["ASC","ASC","DESC","ASC"]},"producerSort":{"fields":["event___sortOrder","stand___sortOrder","contentLevel","sortName"],"order":["ASC","ASC","DESC","ASC"]},"productSort":{"fields":["contentLevel","shortName","vintage"],"order":["DESC","ASC","DESC"]},"locale":"en","eventId":"6048b4376a7c621a467c85d5"},
    },{
      plugin: require('../node_modules/@bottlebooks/gatsby-theme-event/gatsby-browser.js'),
      options: {"plugins":[],"rootPath":"/","homePath":"/producers","exhibitorBase":"partners","producerBase":"producers","productBase":"products","withOverlay":false,"showCountryFilter":true,"landingPageSort":{"fields":["contentLevel","sortName"],"order":["DESC","ASC"]},"exhibitorSort":{"fields":["event___sortOrder","stand___sortOrder","contentLevel","sortName"],"order":["ASC","ASC","DESC","ASC"]},"producerSort":{"fields":["event___sortOrder","stand___sortOrder","contentLevel","sortName"],"order":["ASC","ASC","DESC","ASC"]},"productSort":{"fields":["contentLevel","shortName","vintage"],"order":["DESC","ASC","DESC"]},"locale":"en","eventId":"603f54d910828b19f691ff46"},
    },{
      plugin: require('../node_modules/@bottlebooks/gatsby-theme-event/gatsby-browser.js'),
      options: {"plugins":[],"rootPath":"/","homePath":"/producers","exhibitorBase":"partners","producerBase":"producers","productBase":"products","withOverlay":false,"showCountryFilter":true,"landingPageSort":{"fields":["contentLevel","sortName"],"order":["DESC","ASC"]},"exhibitorSort":{"fields":["event___sortOrder","stand___sortOrder","contentLevel","sortName"],"order":["ASC","ASC","DESC","ASC"]},"producerSort":{"fields":["event___sortOrder","stand___sortOrder","contentLevel","sortName"],"order":["ASC","ASC","DESC","ASC"]},"productSort":{"fields":["contentLevel","shortName","vintage"],"order":["DESC","ASC","DESC"]},"locale":"en","eventId":"6033a2d31d00c321389bf5f7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-55197303-12","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    }]
